<template>
  <div class="datav">
    <nav-header :is-fixed="isFixed" />
    <nav-main
      ref="navMainRef"
      :navs="navs"
      :style="{ background: isFixed ? '#171b22' : '' }"
      @change="onNavChange"
    />
    <div class="nav-shadow"></div>
    <div class="datav-main">
      <div class="datav-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./nav-header.vue";
import NavMain from "./nav-main.vue";
import store from "@/store/index";
export default {
  name: "Home",
  components: {
    NavHeader,
    NavMain,
  },
  data() {
    return {
      isFixed: false,
      menuAuth: false,
      navs: [
        {
          id: 0,
          key: "data",
          path: "/data/home",
          name: "首页",
          icon: "s-data",
          menuAuth: false,
        },
        {
          id: 1,
          key: "station",
          path: "/station",
          name: "站点",
          icon: "document",
          menuAuth: false,
        },
        {
          id: 2,
          key: "history",
          path: "/dataManage",
          name: "设备",
          icon: "document",
          menuAuth: false,
        },
        {
          id: 3,
          key: "dataManage",
          path: "/dataManage",
          name: "数据展示",
          icon: "document",
          menuAuth: false,
        },
        {
          id: 4,
          key: "history",
          path: "#",
          name: "数据清洗",
          icon: "document",
          menuAuth: false,
        },
        {
          id: 5,
          key: "history",
          path: "#",
          name: "设备评估",
          icon: "document",
          menuAuth: false,
        },
        {
          id: 6,
          key: "history",
          path: "#",
          name: "风险决策",
          icon: "document",
          menuAuth: false,
        },
        {
          id: 7,
          key: "device",
          path: "/sensorByqInfo",
          name: "台账管理",
          icon: "document",
          menuAuth: false,
        },
        {
          id: 8,
          key: "houtai",
          path: "/backend",
          name: "后台管理",
          icon: "document",
          menuAuth: false,
        },
        {
          id: 9,
          key: "equipment",
          path: "/equipment",
          name: "设备管理",
          icon: "document",
          menuAuth: false,
        },
      ],
      res: [],
    };
  },
  computed: {
    permissionsComputed() {
      return store.getters.permissions;
    },
  },
  watch: {
    permissionsComputed: {
      handler(newVal) {
        // 当权限数据变化时，更新菜单栏
          this.navs = [];
        if (newVal.length>0) {
          this.navs = [];
          let permissionCode = [];
          permissionCode = this.formatTree(newVal, 0);
          permissionCode.forEach((v, index) => {
            this.navs.push({
              id: index,
              key: v.attribute_id,
              path: v.remarks,
              name: v.attribute_name,
              icon: "document",
            });
          });
          sessionStorage.setItem('menuActive', this.navs[0].id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scroll, false);
  },
  methods: {
    scroll() {
      this.isFixed = this.$refs.navMainRef.$el.offsetTop > 200;
    },
    onNavChange(nav) {
      sessionStorage.setItem('menuActive', nav.id);
      console.log(nav,'nav数据');
      
      if (typeof nav.path == "string" && nav.path) {
        this.$router.push(nav.path);
      }else{
        this.$router.push({...nav.path});
      }
      
    },
    
    // 后端反的格式转化成数组嵌套
    formatTree(arr, attribute_id) {
      let newArr = [];
      arr.forEach((item) => {
        if (item.parent_id == attribute_id) {
          item.children = this.formatTree(arr, item.attribute_id);
          newArr.push({
            ...item,
          });
        }
      });
      return newArr;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll, false);
  },
};
</script>

<style lang="scss" scoped>
.datav {
  .nav-shadow {
    background: linear-gradient(180deg, transparent, #484908);
    height: 10px;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .datav-main {
    user-select: none;

    .datav-content {
      position: relative;
      color: #171b22;
    }
  }
}
</style>
