<template>
  <div>
    <div
      class="datav-hearder"
      :style="{ background: isFixed ? '#171b22' : '' }"
    >
      <div class="datav-nav">
        <div class="header-img">
        </div>
        <div class="user">
          <div class="header-item">
            <el-dropdown>
              <span class="user-link-wrap">
                <el-avatar :size="30">
                  <!-- :src="avatar + '?imageView2/1/w/80/h/80'"-->
                  <img src="../../assets/images/user/888.png" />
                </el-avatar>
                <span class="user-link">
                  {{ userName }} <i class="el-icon-arrow-down"></i>
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="el-icon-s-tools"
                  @click.native="settings"
                  v-if="menu.shebei"
                  >设备管理</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-s-tools"
                  @click.native="houtaisettings"
                  v-if="menu.houtai"
                  >后台管理</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-s-operation"
                  @click.native="handleCommand"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavHeader",
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getData();
    // 得到权限列表中的目录中是否含有设备管理/后台管理等目录
  },
  data() {
    return {
      userName: "admin",
      menu: {
        shebei: false,
        houtai: false
      }
      // url: 'API_V1.0/xadmin/equipment/on_site_inspection/'
    };
  },
  computed: {
    // 获取当前页面权限码
    permissionCode() {
      return this.$store.getters.permissions;
    },
  },
  methods: {
    getData() {
      this.userName = sessionStorage.getItem("userInfo");
      this.permissionCode.forEach(v=>{
        if(v.attribute_name=="台账管理"){
          this.menu.shebei = true
        } else if(v.attribute_name=="后台管理"){
          this.menu.houtai = true
        }
      })
    },
    handleCommand() {
      // 调用退出登录接口
      //把两个变量值为空
      this.$store.dispatch('logout')
      sessionStorage.setItem("token", "");
      sessionStorage.setItem("userInfo", "");
      sessionStorage.setItem("roleIds", "");
      sessionStorage.setItem("permissions", "");
      sessionStorage.setItem("activeMenu", "");
      sessionStorage.setItem("activedata", "");
      sessionStorage.setItem("activeAuth", "");
      sessionStorage.setItem("menuActive", "");
      this.$router.push("/");
    },
    settings() {
      this.$router.push("/sensorByqInfo");
    },
    houtaisettings() {
      this.$router.push("/backend");
    },
  },
};
</script>

<style lang="scss" scoped>
.datav-hearder {
  display: flex;
  position: fixed;
  top: 0;
  justify-content: space-between;
  z-index: 999;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 19%, #4991e4 100%);

  .logo {
    // display: flex;
    flex: 1;
    align-items: center;
    padding-left: 18px;
  }
}

.top-tip {
  padding: 6px 0;
  font-size: 12px;
  color: #bcc9d4;
  display: flex;
  right: 0;
  height: 30px;
  margin-right: 24px;
  width: 600px;

  .tip-strong {
    white-space: nowrap;
    padding-right: 8px;
  }
}

.datav-marquee {
  display: block;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  position: relative;

  .content {
    display: inline-block;
    position: relative;
    padding-right: 0;
    white-space: nowrap;
    animation: marque-animation 20s infinite linear;
    z-index: 0;

    &:hover {
      animation-play-state: paused;
    }
  }

  .content-space {
    display: inline-block;
    width: 5em;
  }

  .project-href {
    color: #bcc9d4;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@keyframes marque-animation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.datav-nav {
  display: flex;
  position: fixed;
  // position: absolute;
  top: -1px;
  flex-direction: column;
  background: transparent;
  width: 100%;
  height: 100px;

  .header-img {
    opacity: 1;
    z-index: 1;
    width: 48%;
    background-image: url("../../assets/images/index/ras-pi-logo.png");
    height: 70px;
    position: absolute;
    background-size: 100% 100%;
    top: 0px;
    left: 30px;
  }

  .nav-img-text {
    z-index: 1;
    transform-origin: 0 0;
    transform: scale(0.5);
    top: 80px;
    left: 40px;
    position: absolute;
    user-select: none;
    cursor: pointer;
  }

  .user {
    justify-content: flex-end;
    padding-right: 15px;
    align-items: center;
    font-size: 14px;
    z-index: 9;
    min-width: 540px;

    .header-item {
      margin: 0 5px;
      padding: 0 10px;
      float: right;
      cursor: pointer;
      user-select: none;
      color: #bcc9d4;
      line-height: 20px;
      height: 20px;
    }

    .user-link-wrap {
      display: flex;
      align-items: center;
    }

    .user-link {
      line-height: 20px;
      height: 20px;
      margin-left: 5px;
      color: #bcc9d4;
    }
  }
  // .nav-img {
  //   position: absolute;
  //   width: 100%;
  //   height: 260px;
  //   background-size: cover;
  //   background-position: center;
  //   background-image: url('../../assets/images/index/nav-img.png');
  // }
}
</style>
