<template>
  <div class="nav-main">
    <canvas
      id="nav-canvas"
      style="position: absolute; z-index: -1; left: 0"
    ></canvas>
    <span v-for="nav in navs" :key="nav.id" class="nav-span">
      <a
        class="nav-link"
        :class="{ 'nav-active': activeNav === activeMenu }"
        @click="toggleNav(nav)"
      >
        <i :class="`el-icon-${nav.icon} nav-icon`"></i>{{ nav.name }}
      </a>
    </span>
  </div>
</template>

<script>
import { debounce } from "@/utils/index";
import eventBus from "@/utils/eventBus.js";
import { NavCanvas } from "./nav-canvas";
export default {
  name: "NavMain",
  props: {
    navs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeNav: 0,
      activeMenu: 0,
      nc: NavCanvas,
      navList: [],
    };
  },
  watch: {
    activeNav: {
      deep: true,
      handler(newValue) {
        this.activeNav = newValue;
      },
    },
    navs: {
      deep: true,
      handler(newValue) {
        this.$nextTick(() => {
      if (newValue.length > 0) {
        this.activeMenu = this.activeMenu ? Number(this.activeMenu) : 0
        this.nc = new NavCanvas(
          "nav-canvas",
          ".nav-main .nav-span",
          this.activeMenu
        );
        window.addEventListener("resize", this.debNavResize);
      }
    });
      },
    },
  },
  created() {
    eventBus.$on("message", (data) => {
      this.toggleNav(data);
    });
    // 从sessionStorage中获取之前保存的激活状态
    const savedActive = sessionStorage.getItem("menuActive");
    if (savedActive) {
      this.activeMenu = savedActive;
    }
  },
  mounted() {
    // this.timeFn();
    // 在这里进行绘图操作
    this.$nextTick(() => {
      if (this.navs.length > 0) {
        this.activeMenu = this.activeMenu ? Number(this.activeMenu) : 0
        this.nc = new NavCanvas(
          "nav-canvas",
          ".nav-main .nav-span",
          this.activeMenu
        );
        window.addEventListener("resize", this.debNavResize);
      }
    });
  },
  methods: {
    toggleNav(nav) {
        this.activeNav = nav.id;
        this.nc.toggle(nav.id);
        this.$emit("change", nav);
      },
    debNavResize() {
      debounce(() => {
        if (this.nc) {
          this.nc.resize();
        }
      }, 500);
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.debNavResize);
  },
};
</script>

<style lang="scss" scoped>
.nav-main {
  z-index: 10;
  display: flex;
  top: 30px;
  position: sticky;
  width: 100%;
  height: 50px;
  margin-top: 80px;
  min-width: 1024px;
  user-select: none;

  .nav-span {
    .nav-link {
      transition: color 0.2s;
      text-decoration: none !important;
      display: block;
      color: #fafafa;
      width: auto;
      min-width: 180px;
      line-height: 40px;
      font-size: 20px;
      text-align: left;
      cursor: pointer;
      padding: 0 25px;

      &.nav-active,
      &:hover {
        color: #fff !important;
      }
    }

    .nav-icon {
      padding-right: 5px;
      font-size: 16px;
    }
  }
}
</style>
